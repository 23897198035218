import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'

import * as Constants from './constants'
import { InputField } from './input-field'
import { SelectField } from './select-field'
import { SubmitButton } from './submit-button'

export function dataSchema() {
  return {
    type: 'object',
    properties: {
      city: {
        type: 'string',
        maxLength: 100,
        pattern: '^[^\\s]+',
      },
      zip: {
        type: 'string',
        pattern: Constants.ZipCodePattern,
      },
      street: {
        type: 'string',
        maxLength: 100,
        pattern: '^[^\\s]+',
      },
      state: {
        type: 'string',
        maxLength: 100,
        pattern: '^[^\\s]+',
      },
    },
    required: ['city', 'zip', 'street', 'state'],
  }
}

export function UpdateProfileForm(props) {
  const schema = React.useMemo(() => dataSchema(), [])

  return (
    <Common.Box>
      <Forms.Provider
        name="my-profile"
        schema={schema}
        onSubmit={props.onSubmit}
      >
        <InputField
          labelFontColor="nav-item"
          scope="#/properties/street"
          initialValue={props.profile.address.street}
          dark
        />
        <Common.Space mt={0} />
        <SelectField
          contentTranslated
          autoComplete="address-level1"
          options={Constants.USA_STATES}
          initialValue={props.profile.address.state}
          scope="#/properties/state"
          backgroundColor="#0C132B"
          disabled
        />
        <Common.Space mt={0} />
        <Common.Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gridGap={0}
          alignItems="start"
          mb={1}
        >
          <InputField
            scope="#/properties/city"
            initialValue={props.profile.address.city}
            labelFontColor="nav-item"
            dark
          />
          <InputField
            scope="#/properties/zip"
            initialValue={props.profile.address.postalCode}
            labelFontColor="nav-item"
            dark
          />
        </Common.Box>
        <SubmitButton stretch>Save</SubmitButton>
      </Forms.Provider>
    </Common.Box>
  )
}

UpdateProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
    }).isRequired,
  }),
}
