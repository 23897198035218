import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as Urql from 'urql'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'

import { Button } from './button'
import { LanguageDropdown } from './language-dropdown'
import { MailInCode } from './mail-in-code'
import { QueryDrawer } from './query-drawer'
import { Spinner } from './spinner'
import { UpdateProfileForm, dataSchema } from './update-profile-form'
import { useMenuQueries } from './use-menu-queries'

const getPlayerQuery = `
query Player {
  configuration {
  country {
  name}
  }
  player {
    profile {
      firstName
      lastName
      phoneNumber {
      prefix
      number
      }
      email
      birthdate
      username
      address {
        city
        postalCode
        street
        state
      }
      language
    }
  }
}
`

const updatePlayerMutation = `
  mutation UpdatePlayer($player: UpdatePlayerInput) {
    updatePlayer(player: $player) {
      ... on UpdatePlayerSuccess {
        player {
          profile {
            address {
              city
              postalCode
              state
              street
            }
          }
        }
      }
      ... on UpdatePlayerFailure {
        error
      }
    }
  }
`

function Divider() {
  return (
    <Common.Box
      width="100%"
      height="1px"
      my={2}
      backgroundColor="secondary-background"
    />
  )
}
function SectionHeader(props) {
  return (
    <Common.Text
      variant="h3"
      fontSize={6}
      fontFamily="head"
      fontWeight="600"
      lineHeight="32px"
    >
      {props.children}
    </Common.Text>
  )
}

SectionHeader.propTypes = {
  children: PropTypes.node,
}

function ProfileField(props) {
  return (
    <Common.Box mt={2}>
      <Common.Text
        color="nav-item"
        fontSize={3}
        fontWeight="bold"
        lineHeight="24px"
        font="head"
      >
        {props.label}
      </Common.Text>
      <Common.Box mt={0}>
        <Common.Text
          fontSize={3}
          lineHeight="16px"
          fontFamily="head"
          color="#CDCED0"
        >
          {props.value}
        </Common.Text>
      </Common.Box>
    </Common.Box>
  )
}

ProfileField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

const preloadSchemaKeys = Forms.findTranslationKeys('my-profile', dataSchema, [
  'placeholder',
  'label',
])

function MyProfile() {
  const history = ReactRouter.useHistory()
  const UrqlClient = Urql.useClient()
  const translate = Herz.I18n.useTranslate()
  const [playerResponse, refetchPlayer] = Urql.useQuery({
    query: getPlayerQuery,
  })

  const [isEditing, setIsEditing] = React.useState(false)

  const { changePasswordQuery } = useMenuQueries()

  function handlePasswordChangeClick() {
    history.push(`?${changePasswordQuery}`)
  }

  function handleSubmit(formErrors, input) {
    if (Object.keys(formErrors).length !== 0) {
      return
    }

    return UrqlClient.mutation(updatePlayerMutation, {
      player: { address: input },
    })
      .toPromise()
      .then(() => {
        refetchPlayer()
        return setIsEditing(false)
      })
  }

  if (playerResponse.fetching) {
    return <Spinner />
  }

  const { profile } = playerResponse.data.player
  const { country } = playerResponse.data.configuration

  return (
    <Common.Box>
      <Common.Box my={3} textAlign="center" color="nav-item">
        <Common.Text variant="h4" fontWeight="bold">
          {profile.username}
        </Common.Text>
      </Common.Box>
      <Common.Box my={2} backgroundColor="drawer-bg" p={1} borderRadius="6px">
        <Common.Box>
          <SectionHeader>
            {translate('my-profile.section-header')}
          </SectionHeader>
          <ProfileField
            label={translate('my-profile.email')}
            value={profile.email}
          />
          <ProfileField
            label={translate('my-profile.phone')}
            value={`${profile.phoneNumber.prefix} ${profile.phoneNumber.number}`}
          />
        </Common.Box>
        <Divider />
        <ProfileField
          label={translate('my-profile.first-name')}
          value={profile.firstName}
        />
        <ProfileField
          label={translate('my-profile.last-name')}
          value={profile.lastName}
        />
        <ProfileField
          label={translate('my-profile.birthdate')}
          value={profile.birthdate}
        />
        <LanguageDropdown />
        <Divider />
        <Common.Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Common.Box>
            <Common.Text
              color="nav-item"
              fontSize={3}
              fontWeight="bold"
              lineHeight="24px"
              font="head"
            >
              {translate('my-profile.password')}
            </Common.Text>
            <Common.Box mt={0}>
              <Common.Text
                fontSize={3}
                lineHeight="16px"
                fontFamily="head"
                color="#CDCED0"
              >
                *********
              </Common.Text>
            </Common.Box>
          </Common.Box>
          <Button onClick={handlePasswordChangeClick} variant="primary" small>
            {translate('my-profile.change-password')}
          </Button>
        </Common.Box>

        <Divider />
        <SectionHeader>{translate('my-profile.address-header')}</SectionHeader>
        {!isEditing ? (
          <Common.Box>
            <ProfileField
              label={translate('my-profile.street')}
              value={profile.address.street}
            />
            <ProfileField
              label={translate('my-profile.state')}
              value={profile.address.state}
            />
            <ProfileField
              label={translate('register.country.label')}
              value={country.name}
            />
            <ProfileField
              label={translate('my-profile.city')}
              value={profile.address.city}
            />
            <ProfileField
              label={translate('my-profile.zip')}
              value={profile.address.postalCode}
            />
            <Common.Box
              mt={1}
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <Button
                variant="primary"
                strech
                onClick={() => setIsEditing(curr => !curr)}
              >
                {translate('my-profile.edit-address')}
              </Button>
            </Common.Box>
          </Common.Box>
        ) : (
          <UpdateProfileForm profile={profile} onSubmit={handleSubmit} />
        )}
      </Common.Box>
      <MailInCode />
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'my-profile.section-header',
    'my-profile.title',
    'my-profile.content',
    'my-profile.basic-content',
    'my-profile.email.label',
    'my-profile.player-id',
    'my-profile.contact-header',
    'my-profile.email',
    'my-profile.phone-number',
    'my-profile.first-name',
    'my-profile.last-name',
    'my-profile.birthdate',
    'my-profile.language',
    'my-profile.password',
    'my-profile.change-password',
    'my-profile.address-header',
    'my-profile.street',
    'my-profile.state',
    'my-profile.city',
    'my-profile.zip',
    'my-profile.edit-address',
    'my-profile.phone',
    'register.country.label',
    ...preloadSchemaKeys,
  ],
  MyProfile
)

export function MyProfileDrawer() {
  const translate = Herz.I18n.useTranslate()
  const { authenticated } = Herz.Auth.useSession()
  const { menuQuery } = useMenuQueries()
  const history = ReactRouter.useHistory()

  if (!authenticated) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="my-profile"
      title={translate('my-profile.title')}
      onSecondaryAction={() => history.push(`?${menuQuery}`)}
    >
      <MyProfile />
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(['my-profile.title'], MyProfileDrawer)

export default MyProfileDrawer
