import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Configuration from './configuration'
import * as Player from './player'
import { Button } from './button'
import { ButtonLoader } from './button-loader'

function generateMailInCode(apiUrl, token) {
  return fetch(`${apiUrl}/octacom/codes`, {
    headers: {
      Accept: 'application/vnd.casinosaga.v1',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    method: 'POST',
  })
}

function shouldDisplayMailInCode(code, timestamp, waiting) {
  if (!code || waiting) {
    return false
  }

  const hourInMs = 60 * 60 * 1000
  const currentTime = Date.now()
  const timeDifference = currentTime - timestamp

  // we don't want to display the code if it's older than an hour
  return timeDifference < hourInMs
}

export function MailInCode() {
  const dispatch = ReactRedux.useDispatch()
  const { token } = Herz.Auth.useSession()
  const translate = Herz.I18n.useTranslate()
  const apiUrl = ReactRedux.useSelector(state =>
    Configuration.getApiUrl(state.configuration)
  )
  const scrollRef = React.useRef(null)

  const mailInCode = ReactRedux.useSelector(state =>
    Player.getMailInCode(state.player)
  )
  const isWaiting = ReactRedux.useSelector(state =>
    Player.getIsMailInCodeWaiting(state.player)
  )

  function handleGenerateCode() {
    dispatch(Player.toggleMailInCodeWaiting(true))

    generateMailInCode(apiUrl, token).catch(() => {
      dispatch(Player.toggleMailInCodeWaiting(false))
    })
  }

  React.useEffect(() => {
    if (mailInCode?.code) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [mailInCode?.code])

  return (
    <Common.Box my={3}>
      <Button
        variant="primary"
        onClick={handleGenerateCode}
        stretch
        disabled={isWaiting}
      >
        {!isWaiting ? (
          translate('mail-in-code.get-code')
        ) : (
          <Common.Box display="flex" justifyContent="center">
            <Common.Box mr={1}>
              {translate('mail-in-code.generating')}
            </Common.Box>
            <Common.Box>
              <ButtonLoader />
            </Common.Box>
          </Common.Box>
        )}
      </Button>
      {shouldDisplayMailInCode(
        mailInCode?.code,
        mailInCode?.createdAt,
        isWaiting
      ) ? (
        <Common.Box mt={2} color="nav-item">
          <Common.Box as="h3" fontWeight="bold" textAlign="center" p={0}>
            {translate('mail-in-code.code-generated')}
          </Common.Box>
          <Common.Text textAlign="center">
            {translate('mail-in-code.copy-code')}
          </Common.Text>
          <Common.Box mt={1}>
            <Common.Box
              ref={scrollRef}
              display="flex"
              alignItems="center"
              borderRadius="4px"
              border="1px solid #2A3149"
              backgroundColor="#0C132B"
              padding="0px 8px"
              color="#CDCED0"
              fontSize={2}
              height="44px"
            >
              {mailInCode?.code}
            </Common.Box>
          </Common.Box>
        </Common.Box>
      ) : null}
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'mail-in-code.get-code',
    'mail-in-code.generating',
    'mail-in-code.code-generated',
    'mail-in-code.copy-code',
  ],
  MailInCode
)
