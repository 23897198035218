import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Http from './http-module'
import * as Icons from './icons'
import { useServerConfiguration } from './server-configuration'

const IconWrapper = styled.div`
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s;
`

const DropdownWrapper = styled.div`
  padding: 5px 0;
  color: #cdced0;
  ${props =>
    css({
      height: props.isOpen ? 'auto' : '30px',
    })}
  transition: all 0.3s ease-in-out;
`

function formatLocalesArray(locales, currentLocale) {
  const fileteredLocales = locales.filter(
    locale => locale.slug !== currentLocale.slug
  )

  return [currentLocale, ...fileteredLocales]
}

export function LanguageDropdown() {
  const dispatch = ReactRedux.useDispatch()
  const prevBasename = Http.useBasename()
  const { authenticated } = Herz.Auth.useSession()
  const url = Http.useUrl()
  const configuration = useServerConfiguration()
  const currentLocale = configuration?.locale
  const locales =
    configuration?.countries?.find(
      country => country.alpha2 === configuration?.country?.alpha2
    )?.locales || []
  const formattedLocales = formatLocalesArray(locales, currentLocale)
  const translate = Herz.I18n.useTranslate(
    () => [
      `l10n.language.${configuration?.locale?.language}`,
      ...locales.map(locale => `l10n.language.${locale.language}`),
    ],
    [configuration.locale.language]
  )
  const [isOpen, setIsOpen] = React.useState(false)

  function handleToggleIsOpen() {
    setIsOpen(curr => !curr)
  }

  function handleLanguageChange(locale) {
    const nextBasename = `/${locale.slug}`
    if (prevBasename.substring(1) !== locale.slug) {
      if (authenticated) {
        dispatch(
          Api.updateMe(
            { language: locale.language },
            {
              success: () => {
                window.location.replace(`${nextBasename}${url}`)
              },
              version: 1,
            }
          )
        )
      }
    }
  }

  return (
    <Common.Box
      width="100%"
      mt="2"
      onClick={handleToggleIsOpen}
      cursor="pointer"
      overflow="hidden"
      transition="0.3s ease-in-out"
    >
      <Common.Box
        display="flex"
        justifyContent="space-between"
        alingItems="center"
        width="100%"
      >
        <Common.Box
          color="nav-item"
          fontSize={3}
          fontWeight="bold"
          lineHeight="24px"
          font="head"
        >
          Lanugage
        </Common.Box>
        <IconWrapper isOpen={isOpen}>
          <Icons.Chevron />
        </IconWrapper>
      </Common.Box>
      <DropdownWrapper isOpen={isOpen}>
        {formattedLocales.map(locale => (
          <Common.Box
            cursor="pointer"
            py="3px"
            color={
              currentLocale.language === locale.language
                ? 'nav-item'
                : 'currentColor'
            }
            key={locale.slug}
            onClick={() => handleLanguageChange(locale)}
          >
            {translate(`l10n.language.${locale.language}`)}
          </Common.Box>
        ))}
      </DropdownWrapper>
    </Common.Box>
  )
}

LanguageDropdown.propTypes = {
  label: PropTypes.string,
}
